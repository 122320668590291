import firebase from "firebase"
import getConfig from "next/config"
import { getProductDomain } from "./Product/ProductContext"
import { map } from "lodash/fp"

const { publicRuntimeConfig } = getConfig()

const VAPID_KEY =
  "BDarrNcJFSpzuVWse_WhOdj5l6IjzmwtMyBAP3M6o66tgZGQHpOebeRIzJ540yizY-FrSU57vyla6N4f9XzDl38"

const FB_PN_TOKEN = "fb_pn_token"
const EVENT_LIST = "event_notification_list"

let firebaseInitialized = false

// init firebase only one time to avoid fast refresh errors
const initFirebaseOnce = () => {
  if (!firebaseInitialized && !firebase.apps.length) {
    firebase.initializeApp({
      apiKey: "AIzaSyBmAFpwMlbjGl3VzNh4dF97NalC9gcRel8",
      authDomain: "wellzesta-health-prd.firebaseapp.com",
      databaseURL: "https://wellzesta-health-prd.firebaseio.com",
      projectId: "wellzesta-health-prd",
      storageBucket: "wellzesta-health-prd.appspot.com",
      messagingSenderId: "466113275295",
      appId: "1:466113275295:web:af8ea9d2bfa954d6c209aa",
      measurementId: "G-NGPG9JWHZW"
    })
    firebaseInitialized = true
  }
}

export const initPushNotifications = async (notificationFn) => {
  initFirebaseOnce()

  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging()

    const currentToken = await messaging.getToken({
      vapidKey: VAPID_KEY
    })

    if (currentToken) {
      window.localStorage.setItem(FB_PN_TOKEN, currentToken)
    }

    const messageEventListenerHandler = (event) => {
      notificationFn(event.data)
    }

    navigator.serviceWorker.removeEventListener("message", messageEventListenerHandler)
    navigator.serviceWorker.addEventListener("message", messageEventListenerHandler)

  } else {
    throw new Error("firebase messaging not supported")
  }
}

function getEventTopicsFormatted(idEventList) {
  let eventTopics = []
  if (idEventList) {
    eventTopics = idEventList.map(idEvent => `Event-${idEvent}`)
  }
  return eventTopics
}

export const subscribeToInitialTopics = async (userId, orgId, idEventList) => {
  const topics = [`User-${userId}`, `Alerts-${orgId}`, `Organization-${orgId}`]
  const eventTopics = getEventTopicsFormatted(idEventList)
  window.localStorage.setItem(EVENT_LIST, JSON.stringify(eventTopics))
  const allTopics = topics.concat(eventTopics)
  await Promise.all(map(subscribeToTopic, allTopics))
}

export const updateEventSubscribeTopics = async (idEventList) => {
  const newEventTopics = getEventTopicsFormatted(idEventList)

  // remove list
  const oldEventTopics = JSON.parse(window.localStorage.getItem(EVENT_LIST))
  const topicsRemove = oldEventTopics.filter(item => !newEventTopics.includes(item))

  await Promise.all(map(unsubscribeFromTopic, topicsRemove))
  window.localStorage.removeItem(EVENT_LIST)
  // new list
  const topicsAdd = newEventTopics.filter(item => !oldEventTopics.includes(item))

  await Promise.all(map(subscribeToTopic, topicsAdd))
  window.localStorage.setItem(EVENT_LIST, JSON.stringify(newEventTopics))
}

export const unsubscribeFromTopics = async (user, organization, idEventList) => {
  const topics = [`User-${user.id}`, `Alerts-${organization.id}`, `Organization-${organization.id}`]
  let eventTopics = []

  if (idEventList) {
    eventTopics = getEventTopicsFormatted(idEventList)
  } else {
    eventTopics = JSON.parse(window.localStorage.getItem(EVENT_LIST))
  }

  const allTopics = topics.concat(eventTopics)
  await Promise.all(map(unsubscribeFromTopic, allTopics))
}

export const subscribeToTopic = async (topic) => {
  const currentToken = window.localStorage.getItem(FB_PN_TOKEN)
  if (!currentToken)
    throw new Error(
      "No push notification token found. Make sure to call initPushNotifications first."
    )

  const productName = getProductDomain(window.location.hostname).productDomain

  return requestSubscribeToTopic(
    currentToken,
    `${publicRuntimeConfig.FIREBASE_ENV}-${productName}-${topic}`
  )
}

export const unsubscribeFromTopic = async (topic) => {
  const currentToken = window.localStorage.getItem(FB_PN_TOKEN)

  if (!currentToken)
    throw new Error(
      "No push notification token found. Make sure to call initPushNotifications first."
    )

  const productName = getProductDomain(window.location.hostname).productDomain

  return requestUnsubscribeFromTopic(
    currentToken,
    `${publicRuntimeConfig.FIREBASE_ENV}-${productName}-${topic}`
  )
}

export const unregisterMessagingSW = () => {
  if (window.navigator.serviceWorker) {
    window.navigator.serviceWorker
      .getRegistrations()
      .then(function(registrations) {
        for (let registration of registrations) {
          if (registration.active.scriptURL.indexOf("firebase-messaging-sw.js") !== -1) {
            registration.unregister()
          }
        }
      })
  }
}

async function requestSubscribeToTopic(token, topic) {
  const response = await window.fetch("/api/subscribe_to_topic", {
    method: "POST",
    body: JSON.stringify({ token, topic })
  })

  const data = await response.json()
  if (!data.success) {
    throw new Error(data.message)
  }

  return data
}

async function requestUnsubscribeFromTopic(token, topic) {
  const response = await window.fetch("/api/unsubscribe_from_topic", {
    method: "POST",
    body: JSON.stringify({ token, topic })
  })

  const data = await response.json()
  if (!data.success) {
    throw new Error(data.message)
  }

  return data
}
